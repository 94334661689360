<template>
    <homepage v-if="form.page.type=='homepage'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentHomepageBlok  v-if="content.component=='ContentHomepageBlok' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                          :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentTitle  v-if="content.component=='ContentTitle' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </homepage>

    <search v-if="form.page.type=='search'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">

                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>

            </template>
        </template>
    </search>

    <product v-if="form.page.type=='product'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </product>

    <news v-if="form.page.type=='news'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
           </template>
        </template>
    </news>

    <news-show v-if="form.page.type=='news-show'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </news-show>

    <sitemap v-if="form.page.type=='sitemap'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </sitemap>

    <contact v-if="form.page.type=='contact'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentTitle  v-if="content.component=='ContentTitle' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                               :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </contact>


    <favourite v-if="form.page.type=='favourite'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </favourite>

    <page v-if="form.page.type=='page'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentAccordion v-if="content.component=='ContentAccordion' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                  :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentTitle  v-if="content.component=='ContentTitle' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                               :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </page>

    <error404 v-if="form.page.type=='error404'" v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">
        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">
            <template v-for="content in form.page.contents">
                <ContentAccordion v-if="content.component=='ContentAccordion' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                  :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentText  v-if="content.component=='ContentText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                              :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentTitle  v-if="content.component=='ContentTitle' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                               :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
                <ContentPlainText  v-if="content.component=='ContentPlainText' && content.location===content_location" :content="content" :pages="pages" :editable="false"
                                   :contents="filterContentLocation(content_location)" @clicked="refreshContent(false)"/>
            </template>
        </template>
    </error404>


    <!--    <component v-bind:is="form.page.type" :menu_items="menu_items" :page="page" :editable="false" :website="website">-->

<!--        <template v-slot:[content_location] v-for="(allowed_types_content_location,content_location) in form.page.content_locations">-->
<!--            <template  v-for="content in form.page.contents">-->
<!--                <component :is="content.component" :content="content" :pages="pages" :editable="false"-->
<!--                           v-if="content.location===content_location" :contents="filterContentLocation(content_location)"   @clicked="refreshContent(false)"/>-->
<!--            </template >-->
<!--        </template>-->
<!--    </component>-->
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import JetFormSection from "@/Jetstream/FormSection.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetInputError from "@/Jetstream/InputError.vue";
import JetButton from '@/Jetstream/Button.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetFormErrors from '@/Jetstream/FormErrors.vue';
//import Edit from '@/components/layouts/Edit.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';

//import Basepage from '@/components/layouts/Basepage.vue';

//content types
import ContentAccordion from '@/Pages/Front/Page/Contents/Accordion.vue';
import ContentHomepageBlok from '@/Pages/Front/Page/Contents/HomepageBlok.vue';
import ContentImage from '@/Pages/Front/Page/Contents/Image.vue';
import ContentText from '@/Pages/Front/Page/Contents/Text.vue';
import ContentTitle from '@/Pages/Front/Page/Contents/Title.vue';
import ContentPlainText from '@/Pages/Front/Page/Contents/PlainText.vue';


//page types
import homepage from '@/Pages/Admin/Page/Templates/Homepage.vue';
import page from '@/Pages/Admin/Page/Templates/Page.vue';
import search from "@/Pages/Admin/Page/Templates/Search.vue";
import product from "@/Pages/Admin/Page/Templates/Product.vue";
import news from '@/Pages/Admin/Page/Templates/News.vue';
import newsShow from '@/Pages/Admin/Page/Templates/NewsShow.vue';
import contact from '@/Pages/Admin/Page/Templates/Contact.vue';
import error404 from '@/Pages/Admin/Page/Templates/Error404.vue';
import sitemap from '@/Pages/Admin/Page/Templates/Sitemap.vue';
import favourite from '@/Pages/Admin/Page/Templates/Favourite.vue';


export default {

    components: {
        AppLayout,
        JetFormSection,
        JetLabel,
        JetInput,
        JetInputError,
        JetButton,
        JetActionMessage,
        JetFormErrors,
        JetSelect,
        JetCheckbox,
      //  Edit,

        ContentAccordion,
        ContentHomepageBlok,
        ContentImage,
        ContentText,
        ContentTitle,
        ContentPlainText,

      //  Basepage,
        homepage,
        search,
        product,
        error404,
        page,
        news,
        newsShow,
        contact,
        sitemap,
        favourite,
    },
    props: {
        page: Object,
        pages: Object,
        page_logs: Object,
        page_types: Object,
        content_types: Object,
        menu_items: Object,
        menu_positions: Object,
        page_options: Object,
        order: Object,
        website:Object,
    },

    data() {
        return {

            form: this.$inertia.form({
                page: this.page,
            }),
        }
    },

    methods: {
        filterContentLocation(content_location) {
            let items = this.page.contents;
            return Object.keys(items)
                .map(key => items[key]) // turn an array of keys into array of items.
                .filter(item => item.location === content_location) // filter that array,
        }
    },
}
</script>

<style scoped>

</style>

